import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Dakolanites Sports Pub
			</title>
			<meta name={"description"} content={"Located in the heart of the action, Dakolanites isn't just your typical sports pub – it's the lively Pub where the love for the game meets the joy of great company. Join us for a one-of-a-kind experience where every night is filled with laughter, cheers, and the thrill of friendly competition."} />
			<meta property={"og:title"} content={"Home | Dakolanites Sports Pub"} />
			<meta property={"og:description"} content={"Located in the heart of the action, Dakolanites isn't just your typical sports pub – it's the lively Pub where the love for the game meets the joy of great company. Join us for a one-of-a-kind experience where every night is filled with laughter, cheers, and the thrill of friendly competition."} />
			<meta property={"og:image"} content={"https://dakolanites.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://dakolanites.com/img/image_processing.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://dakolanites.com/img/image_processing.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://dakolanites.com/img/image_processing.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://dakolanites.com/img/image_processing.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://dakolanites.com/img/image_processing.png"} />
			<meta name={"msapplication-TileImage"} content={"https://dakolanites.com/img/image_processing.png"} />
			<meta name={"msapplication-TileColor"} content={"https://dakolanites.com/img/image_processing.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://dakolanites.com/img/banner.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			min-height="700px"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Where the game is played, friendships are made!
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="100px 0">
							Dakolanites Sports Pub
						</Text>
						<Text color="--lightD2" letter-spacing="1px" margin="0">
							Located in the heart of the action, Dakolanites isn't just your typical sports pub – it's the lively Pub where the love for the game meets the joy of great company.{" "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-11"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Why Dakolanites Pub?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--dark"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Join us for a one-of-a-kind experience where every night is filled with laughter, cheers, and the thrill of friendly competition.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Games Galore
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
							From intense rounds of beer pong to laid-back games of shuffleboard, we've got a variety of activities to keep the good times rolling.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Unwind in Style
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
							Our carefully curated selection of craft beers and mouthwatering bites make every visit a celebration of flavours and camaraderie.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Casual Connections
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
							Whether you're a regular or a first-timer, our friendly staff and welcoming atmosphere make it easy to strike up a conversation and make new connections.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Menu{" "}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					This menu is just a glimpse – there's a world of culinary delights waiting for you. Have dietary concerns or specific preferences? Reach out to our team, and we'll ensure your visit is not just memorable but perfectly tailored to your tastes.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://dakolanites.com/img/m1.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Craft Beer Selection:
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							• Local Legends Lager: Crisp and refreshing, a local favourite.
							<br />
							• Hoppy Hour IPA: Bold and bitter, a hop lover's delight.
							<br />
							• Goalpost Pilsner: Light and easy-drinking, the perfect game day choice.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://dakolanites.com/img/m2.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Brewmaster's Picks:
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							• Brewmaster's Flight: A selection of our master brewer's top picks for the ultimate tasting experience.
							<br />
							• Signature Stout: A rich and robust brew, perfect for those who savour the darker side of beer.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://dakolanites.com/img/m3.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Bites Combos:
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							• Craft Beer Sliders: Mini burgers paired with a flight of our finest craft beers – a match made in Brews & Banter heaven.
							<br />
							• Brewmaster's Platter: A generous assortment of handcrafted appetisers, perfect for sharing during intense matches.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Join Our League of Brewmasters
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Questions, reservations, or just want to chat about the latest game?
				</Text>
				<Link href="/contacts" color="#000000" margin="30px 0px 0px 0px">
					Contact Us{" "}
				</Link>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://dakolanites.com/img/about.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});